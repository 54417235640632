<template>
    <div class="ecommerce-searchbar mt-1" style="height:500px" >
      <b-row>
        <b-col cols="12" style="height:500px" v-if="currentab === true">
        <gantt class="left-container" 
          :tasks="maintenanceList" 
          @store="store = $event"
          ></gantt>
        </b-col>
      </b-row>  
  </div>

</template>

<script>

import Gantt from '../../../../components/GanttPluri.vue';

import {
   BRow, BCol,
} from 'bootstrap-vue'
 
export default {
  name: 'app',
  components: {Gantt,BRow,BCol},
data () {
      
    return {
      maintenanceList: {
        data: [
            {id:1, text:'maintenance 1', start_date:'2022-01-01',duration:365, status:'planifié', ouvrage:'GU148',budget:'50 000',iqoa:'3',vetuste:'75'},
            {id:2, text:'maintenance 2', start_date:'2025-01-01',duration:365, status:'planifié', ouvrage:'AL623C',budget:'20 000',iqoa:'1',vetuste:'21'},
            {id:3, text:'maintenance 3', start_date:'2023-01-01',duration:365, status:'planifié', ouvrage:'GU156',budget:'35 000',iqoa:'2E',vetuste:'54'},
            {id:4, text:'maintenance 4', start_date:'2026-01-01',duration:365, status:'planifié', ouvrage:'Pont54',budget:'112 000',iqoa:'2',vetuste:'38'},
            {id:5, text:'maintenance 5', start_date:'2027-01-01',duration:365, status:'planifié', ouvrage:'GU148',budget:'25 000',iqoa:'3',vetuste:'75'},
            {id:6, text:'maintenance 6', start_date:'2028-01-01',duration:365, status:'planifié', ouvrage:'AL623C',budget:'19 000',iqoa:'1',vetuste:'21'},
        ],
        links: [],
      },
      currentab : true
    }
  },

   methods: {
   
  },

}
</script>

<style>
  html, body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  .container {
    height: 100%;
    width: 100%;
  }
  .left-container {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
  .gantt_task_cell {
    width: 35px;
  }
</style>