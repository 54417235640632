{{ src/components/Gantt.vue }}
<template>
  <div ref="ganttpluri">
  </div>
</template>
 
<script>
import {gantt} from 'dhtmlx-gantt';
export default {
  name: 'gantt',
  props: {
    tasks: {
      type: Object,
      default () {
        return {data: [], links: []}
      }
    },  
  },

    data() {
       return {
           inspectionTask:{id:''}
       }     
    },
 

  mounted: function () {

  //gantt.config.xml_date = "%Y-%m-%d";
  gantt.config.xml_date = "%Y-%m-%d";
 	gantt.config.min_column_width = 20;
	gantt.config.scale_height = 90;
	gantt.i18n.setLocale("fr");


gantt.templates.task_class  = function(start, end, task){

            return "high";
};    

gantt.config.date_grid = "%d/%m/%Y";
	gantt.config.scales = [
		{unit: "year", step: 1, format: "%Y"},
	];


  gantt.config.columns = [
      { name: "ouvrage", label: "Ouvrage", width: "100" },
      { name: "iqoa", label: "IQOA", width: "50", align: "center" },
      { name: "vetuste", label: "Vétusté", width: "50", align: "center" },
    { name: "text", label: "Opération", width: "250" },
      { name: "budget", label: "Budget", width: "80", align: "center" },   

  ];  

    gantt.init(this.$refs.ganttpluri);
    gantt.clearAll();
    gantt.parse(this.$props.tasks);
  }
}
</script>
 
<style>
    @import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
    .gantt_task_line{
        border-color: rgba(0, 0, 0, 0.25);
    }
    .gantt_task_line .gantt_task_progress {
        background-color: rgba(0, 0, 0, 0.25);
    }
 
    /* high */
    .gantt_task_line.high {
        background-color: #03A9F4;
    }
    .gantt_task_line.high .gantt_task_content {
        color: #fff;
    }
 
    /* medium */
    .gantt_task_line.medium {
        background-color: #f57730;
    }
    .gantt_task_line.medium .gantt_task_content {
        color: #fff;
    }
 
    /* low */
    .gantt_task_line.low {
        background-color: #e157de;
    }
    .gantt_task_line.low .gantt_task_content {
        color: #fff;
    } 
    
    /* low */
    .gantt_task_line.ended {
        background-color: #3fa33c;
    }
    .gantt_task_line.ended .gantt_task_content {
        color: #fff;
    }    

</style>